<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos en Sitio Web</h4>
            <div class="small text-muted">Administración de productos para mostrar en el sitio web</div>
          </b-col>
          
          <b-col sm="5">            
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      <b-row>
        <b-col lg="12" id="view_filters_products">    
          <b-card v-if="table.mostrarFiltros" id="list-products">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>                
                <b-form-input type="text" 
                              placeholder="Ingrese un código o nombre de producto" 
                              v-model="filters.name"
                              size="sm"
                              v-on:keyup.enter.native="filterProducts">
                </b-form-input>              
              </b-col>
              <b-col sm="3">
                <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
                <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>                
              </b-col>  
              
              <b-col sm="3" v-if="hasLines">
                <v-select :options="arr.select.lines" v-model="filters.lines" placeholder="Lineas" :multiple="false" :select-on-tab="true" @input="filterLoadSubLines"></v-select>
                <v-select :options="arr.select.sublines" v-model="filters.sublines" placeholder="Sublineas" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>   
                    
              <b-col sm="2">                
                <v-select :options="arr.select.published" v-model="filters.published" placeholder="Publicado" :multiple="false" :select-on-tab="true"></v-select>
                <v-select :options="arr.select.featured" v-model="filters.featured" placeholder="Destacados" :multiple="false" :select-on-tab="true"></v-select>
                <v-select :options="arr.select.not_sell" v-model="filters.not_sell" placeholder="Vender" :multiple="false" :select-on-tab="true"></v-select>                
              </b-col>

              <b-col sm="1">
                <b-button variant="outline-dark" @click="filterProducts()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_list_products">
          <b-card>
            <b-row>
              <b-col sm="12" class="mt-2">
                <b-table class="mb-0 table-products-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected" 
                        :busy="table.isBusy"                     
                        v-if="table.items.length || table.isBusy">                                               
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:row-details="row">
                      <b-row class="w-100 p-0 m-0">
                        <b-col lg="4" v-if="row.item.subcategory">
                          <legend class="crud-products-title">Categorías</legend>
                          <b-icon icon="chevron-double-right"></b-icon> {{row.item.subcategory.category.name}}<br>
                          <b-icon icon="blank" ></b-icon>
                          <b-icon icon="chevron-right"></b-icon> {{row.item.subcategory.name}}                          
                        </b-col>                         
                        <b-col lg="4" v-if="row.item.subline">
                          <div v-if="hasLines">
                            <legend class="crud-products-title">Linea</legend>
                            <b-icon icon="chevron-double-right" ></b-icon> {{row.item.subline.line.name}} <br>
                            <b-icon icon="blank" ></b-icon>
                            <b-icon icon="chevron-right" ></b-icon> {{row.item.subline.name}}<br>
                          </div>
                        </b-col>
                        <b-col lg="4" v-if="hasMeasurement">
                          <legend class="crud-products-title">Atributos</legend>
                          <b-icon icon="dot" ></b-icon> Profundidad: {{row.item.depth}} cm.<br>
                          <b-icon icon="dot" ></b-icon> Largo: {{row.item.long}} cm.<br>
                          <b-icon icon="dot" ></b-icon> Alto: {{row.item.height}} cm.
                          <b-icon icon="dot" ></b-icon> Peso: {{row.item.weight}} kg.
                        </b-col>                       
                      </b-row>
                    </template>
                                                  
                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(sell)="data">
                      <span v-if="!data.item.not_sell" title="Se vende por sitio web">
                          <i class="fa fa-cart-plus crud-product-sell-custom"></i>
                      </span>                          
                      <span v-else title="No se vende por sitio web">
                          <i class="fa fa-arrow-down crud-product-notsell-custom"></i>
                      </span>      
                    </template>

                    <template v-slot:cell(image)="data">
                      <b-img  :src="data.item.image[0]" 
                              fluid 
                              class="crud-product-image" 
                              v-if="data.item.image"/>
                      <b-icon icon="image" 
                              v-else 
                              class="h2" 
                              v-b-tooltip.hover
                              title="Imagen No Disponible" />
                    </template>

                    <template v-slot:cell(name)="data">
                      <div class="crud-products-title">
                        <span v-if="data.item.code" class="crud-products-code">
                          {{data.item.code}} 

                          <span>
                            <b-badge variant="success" v-if="data.item.published">PUBLICADO</b-badge>
                            <b-badge variant="danger" v-else>NO PUBLICADO</b-badge>
                          </span>

                          <span v-if="data.item.featured" title="Producto Destacado">
                              <i class="fa fa-fire crud-product-featured-custom"></i>                              
                          </span>                                              
                        </span><br>
                        {{data.item.name}}
                      </div>
                    </template>

                    <template v-slot:cell(variant)="data">                            
                      <div v-if="hasColorsWaist">                  
                        <b-button v-if="data.item.colors_waist.length" 
                                  @click="openVariant(data.item)"
                                  variant="secondary"
                                  size="sm"
                                  class="crud-products-table-button-variant">
                          {{data.item.colors_waist.length}} Items
                          <b-icon icon="chevron-right"></b-icon>
                        </b-button>
                        <b-button v-else 
                                  @click="openVariant(data.item)"
                                  variant="outline-secondary"
                                  size="sm"
                                  v-b-tooltip.hover
                                  title="Cargar Variedades"
                                  class="crud-products-table-button-variant">
                          <b-icon icon="chevron-double-right"></b-icon>
                        </b-button>
                      </div>
                      <div v-else>
                        <div v-if="hasColors">
                          <b-button v-if="data.item.colors.length"
                                    @click="openVariant(data.item)"
                                    variant="secondary"
                                    size="sm"
                                    class="crud-products-table-button-variant">
                            {{data.item.colors.length}} Colores
                            <b-icon icon="chevron-right"></b-icon>
                          </b-button>
                          <b-button v-else 
                                    @click="openVariant(data.item)"
                                    variant="outline-secondary"
                                    size="sm"
                                    v-b-tooltip.hover
                                    title="Cargar Colores"
                                    class="crud-products-table-button-variant">
                            <b-icon icon="chevron-double-right"></b-icon>
                          </b-button>                          
                        </div>
                        <div v-else>
                          <div v-if="hasWaist">
                            <b-button v-if="data.item.waist.length"
                                      @click="openVariant(data.item)"
                                      variant="secondary"
                                      size="sm"
                                      class="crud-products-table-button-variant">
                              {{data.item.waist.length}} Talles
                              <b-icon icon="chevron-right"></b-icon>
                            </b-button>
                            <b-button v-else 
                                      @click="openVariant(data.item)"
                                      variant="outline-secondary"
                                      size="sm"
                                      v-b-tooltip.hover
                                      title="Cargar Talles"
                                      class="crud-products-table-button-variant">
                              <b-icon icon="chevron-double-right"></b-icon>
                            </b-button>                            
                          </div>
                        </div>
                      </div>
                    </template>       

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableProductsShow"
                                  @hide="setConfigTableProductsHide">

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="5" id="contain_variant_items" v-if="hasAnyVariant">
          <div id="variant_items">
            <b-card v-if="showVariant">            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PRODUCTO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <div class="crud-products-title" v-if="itemSelected">
                        <div class="crud-products-title">
                          <span v-if="itemSelected.code" class="crud-products-code">
                            {{this.itemSelected.code}} 
                          </span><br>
                          {{this.itemSelected.name}}
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12" class="crud-product-close-variant">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="showVariant" @click="showVariant=false" v-b-tooltip.hover title="Ocultar Variedad">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- VARIANTES -->                
                  <b-row>
                    <!-- COLOR - TALLE -->
                    <b-col md="12" v-if="hasColorsWaist">                    
                      <b-table  id="table-color-waist"
                                class="mb-0 table-products-variant"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubColorsWaist.items"
                                :fields="tableSubColorsWaist.fields"                            
                                v-if="tableSubColorsWaist.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:cell(color)="data">    
                          <span v-if="data.item.color.hexa">
                            <b-icon icon="square-fill" 
                                    class="crud-product-table-show" 
                                    :style="'color:' + data.item.color.hexa">
                            </b-icon>
                          </span>
                          <span v-else>                            
                            <b-img v-if="data.item.color.plot" 
                                  :src="data.item.color.plot" 
                                  :height="30"
                                  class="crud-product-table-show">
                            </b-img>
                          </span>                                
                          <b-icon icon="blank" class="crud-product-table-show" :style="'color:' + data.item.color.hexa"></b-icon>                                  
                          {{data.item.color.name}}                                
                        </template>

                        <template v-slot:cell(waist)="data">                                    
                          {{data.item.waist.name}}
                        </template>

                        <template v-slot:cell(f_action)="data">
                          <b-dropdown right 
                                      text="Acción" 
                                      size="sm" 
                                      variant="outline-dark" 
                                      class="pull-right" 
                                      @show="setConfigTableVariantShow"
                                      @hide="setConfigTableVariantHide">
                            <b-dropdown-header>Acciones</b-dropdown-header>                         
                            <b-dropdown-item @click="editSub(data.item)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>

                      </b-table>
                      <b-alert v-else variant="warning" show>No se encontraron variedades de colores + talles</b-alert>
                    </b-col>
                    <b-col v-else>
                      <b-row>
                        <!-- COLOR-->
                        <b-col md="12" v-if="hasColors">                        
                          <b-table class="mb-0 table-products-variant"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableSubColors.items"
                                  :fields="tableSubColors.fields"                            
                                  v-if="tableSubColors.items.length">   

                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:cell(color)="data">    
                              <span v-if="data.item.color.hexa">
                                <b-icon icon="square-fill" 
                                        class="crud-product-table-show" 
                                        :style="'color:' + data.item.color.hexa">
                                </b-icon>
                              </span>
                              <span v-else>                            
                                <b-img v-if="data.item.color.plot" 
                                      :src="data.item.color.plot" 
                                      :height="30"
                                      class="crud-product-table-show">
                                </b-img>
                              </span>                                
                              <b-icon icon="blank" class="crud-product-table-show" :style="'color:' + data.item.color.hexa"></b-icon>                                  
                              {{data.item.color.name}}                                
                            </template>

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right 
                                          text="Acción" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right"
                                          @show="setConfigTableVariantShow"
                                          @hide="setConfigTableVariantHide">
                                <b-dropdown-header>Acciones</b-dropdown-header>                                             
                                <b-dropdown-item @click="editSub(data.item)">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeSub(data.item)">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>

                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron variedades de colores</b-alert>
                        </b-col>

                        <!-- TALLE -->
                        <b-col md="12" v-if="hasWaist">                                                
                          <b-table class="mb-0 table-products-variant"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableSubWaist.items"
                                  :fields="tableSubWaist.fields"                            
                                  v-if="tableSubWaist.items.length">   
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:cell(waist)="data">                                    
                              {{data.item.waist.name}}
                            </template>

                            <template v-slot:cell(f_action)="data">                                                            
                              <b-dropdown right 
                                          text="Acción" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right"
                                          @show="setConfigTableVariantShow"
                                          @hide="setConfigTableVariantHide">
                                <b-dropdown-header>Acciones</b-dropdown-header>                         
                                <b-dropdown-item @click="editSub(data.item)">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeSub(data.item)">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>

                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron variedades de talles</b-alert>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-alert v-else
                    show
                    variant="warning">
              Seleccione un producto de la lista para poder agregar variedades
            </b-alert>
          </div>
        </b-col>
      </b-row>          

      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_footer_products">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"  
                                @input="filterProducts()"/>
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>                        
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código"
                                :disabled="true">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre"
                                :disabled="true">
                  </b-form-input>
                </b-form-group>
              </b-col>             
            </b-row>     

            <b-row>   
              <b-col md="6">
                <b-row>   
                  <b-col md="12">                
                    <FindObject type="productsCategory" 
                                @select-object="loadProductsCategory($event)" 
                                :valueID="crud.form.category_id"
                                tag="Categorías"
                                render="search"
                                :disabled="true"/>
                  </b-col>
                  <b-col md="12">                    
                    <FindObject type="productsSubCategory" 
                                @select-object="loadProductsSubcategory($event)" 
                                :valueID="crud.form.subcategory_id"
                                tag="Sub Categoría"
                                render="search"
                                :disabled="true"/>
                  </b-col>
                </b-row>                                
              </b-col>               
              <b-col md="6" v-if="hasLines">
                <b-row>
                  <b-col md="12">                                
                    <FindObject type="productsLine" 
                                @select-object="loadProductsLine($event)" 
                                :valueID="crud.form.line_id"
                                tag="Lineas"
                                render="search"
                                :disabled="true"/>
                  </b-col>                
                  <b-col>
                    <FindObject type="productsSubLine" 
                                @select-object="loadProductsSubline($event)" 
                                :valueID="crud.form.subline_id"
                                render="search"
                                :disabled="true"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row> 
              <b-col md="12">
                <b-form-group label="Descripción Corta">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.description_short"                                
                                placeholder="Ingresar una descripción">
                  </b-form-input>
                </b-form-group>
              </b-col>              
            </b-row>
          </b-tab>
        
          <b-tab no-body title="Detalle">
            <b-row>                               
              <b-col md="4">                
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Galería">
                      <Imagen :images="crud.form.image" 
                              @load-image="crud.form.image = $event" 
                              typeImage="Imagenes" 
                              :multipleImage="true" 
                              :compressImage="true"
                              :addWaterMark="true"/>
                    </b-form-group>                            
                  </b-col>           
                </b-row>
              </b-col>
              <b-col md="8">
                <b-row>
                  <b-col md="12">
                    <b-tabs card>
                      <b-tab no-body title="Descripción">
                        <b-row>
                          <b-col md="12">
                            <vue-editor class="product-web-ql-editor" v-model="crud.form.description"></vue-editor>
                          </b-col>  
                        </b-row>
                      </b-tab>
                      <b-tab no-body title="Descripción Extendida">
                        <b-row>
                          <b-col md="12">
                            <vue-editor class="product-web-ql-editor" v-model="crud.form.description_extended"></vue-editor>
                          </b-col>  
                        </b-row>
                      </b-tab>
                      <b-tab no-body title="Opciones"> 
                        <b-row>
                          <b-col md="12">                    
                            <b-form-checkbox v-model="crud.form.published" 
                                              switch 
                                              size="sm" 
                                              class="pull-left">
                              Publicado (muestra en sitio web)
                            </b-form-checkbox>            
                          </b-col>                  
                          <b-col md="12" class="mt-3">   
                            <hr>
                          </b-col>                          
                          <b-col md="12" class="mt-3">                    
                            <b-form-checkbox v-model="crud.form.featured" 
                                              switch 
                                              size="sm" 
                                              class="pull-left">
                              Destacado (muestra en bloque destacados)
                            </b-form-checkbox>            
                          </b-col>      
                          <b-col md="12" class="mt-3">   
                            <hr>
                          </b-col>
                          <b-col md="12" class="mt-3">                    
                            <b-form-checkbox v-model="crud.form.not_sell" 
                                              switch 
                                              size="sm" 
                                              class="pull-left">
                              No vender desde sitio web (Se activa la funcionalidad de la solapa "Externo")
                            </b-form-checkbox>            
                          </b-col>                          
                          <b-col md="12" class="mt-3">                    
                            <b-form-checkbox v-model="crud.form.not_decimal_price_website" 
                                              switch 
                                              size="sm" 
                                              class="pull-left">
                              Ocultar los decimales del precio en sitio web (Redondea el importe al visualizar el precio)
                            </b-form-checkbox>            
                          </b-col>                                                                                        
                        </b-row>
                      </b-tab>                      
                      <b-tab no-body title="Adjuntos"> 
                        <b-row>
                          <b-col md="12">
                            <b-row>
                              <b-col md="6">
                                <b-form-group label="Nombre">            
                                  <b-form-input type="text"
                                                size="sm"
                                                v-model="crud.form.file_name"                                
                                                placeholder="Ingresar un nombre">
                                  </b-form-input>          
                                </b-form-group>                      
                              </b-col>                                                          
                              <b-col md="6">
                                <b-form-group label="Archivo">                
                                  <b-form-file v-if="!crud.form.fileLink" 
                                                v-model="crud.form.file"                             
                                                size="sm"
                                                :state="Boolean(crud.form.file)"
                                                placeholder="Elija un archivo o sueltelo aquí..."
                                                drop-placeholder="Suelta el archivo aquí...">
                                  </b-form-file>      
                                  <div v-else>
                                    <b-link :href="crud.form.fileLink" target="_blank">
                                      <b>Ver Archivo</b>
                                    </b-link>           
                                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                                      <i class="fa fa-refresh"></i>
                                    </b-button>                                         
                                  </div>
                                </b-form-group>
                              </b-col>                                  
                            </b-row>
                          </b-col>       

                          <b-col md="12">
                            <b-row>
                              <b-col md="6">
                                <b-form-group label="Nombre">            
                                  <b-form-input type="text"
                                                size="sm"
                                                v-model="crud.form.file1_name"                                
                                                placeholder="Ingresar un nombre">
                                  </b-form-input>          
                                </b-form-group>                      
                              </b-col>                                                          
                              <b-col md="6">
                                <b-form-group label="Archivo">                
                                  <b-form-file v-if="!crud.form.file1Link" 
                                                v-model="crud.form.file1"                             
                                                size="sm"
                                                :state="Boolean(crud.form.file1)"
                                                placeholder="Elija un archivo o sueltelo aquí..."
                                                drop-placeholder="Suelta el archivo aquí...">
                                  </b-form-file>      
                                  <div v-else>
                                    <b-link :href="crud.form.file1Link" target="_blank">
                                      <b>Ver Archivo</b>
                                    </b-link>           
                                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.file1Link=''" v-b-tooltip.hover title="Cargar otro archivo">
                                      <i class="fa fa-refresh"></i>
                                    </b-button>                                         
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>  

                          <b-col md="12">
                            <b-row>
                              <b-col md="6">
                                <b-form-group label="Nombre">            
                                  <b-form-input type="text"
                                                size="sm"
                                                v-model="crud.form.file2_name"                                
                                                placeholder="Ingresar un nombre">
                                  </b-form-input>          
                                </b-form-group>                      
                              </b-col>                                                          
                              <b-col md="6">                            
                                <b-form-group label="Archivo">                
                                  <b-form-file v-if="!crud.form.file2Link" 
                                                v-model="crud.form.file2"                             
                                                size="sm"
                                                :state="Boolean(crud.form.file2)"
                                                placeholder="Elija un archivo o sueltelo aquí..."
                                                drop-placeholder="Suelta el archivo aquí...">
                                  </b-form-file>      
                                  <div v-else>
                                    <b-link :href="crud.form.file2Link" target="_blank">
                                      <b>Ver Archivo</b>
                                    </b-link>           
                                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.file2Link=''" v-b-tooltip.hover title="Cargar otro archivo">
                                      <i class="fa fa-refresh"></i>
                                    </b-button>                                         
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col md="12">
                            <b-row>
                              <b-col md="6">
                                <b-form-group label="Nombre">            
                                  <b-form-input type="text"
                                                size="sm"
                                                v-model="crud.form.file3_name"                                
                                                placeholder="Ingresar un nombre">
                                  </b-form-input>          
                                </b-form-group>                      
                              </b-col>                                                          
                              <b-col md="6">                            
                                <b-form-group label="Archivo">                
                                  <b-form-file v-if="!crud.form.file3Link" 
                                                v-model="crud.form.file3"                             
                                                size="sm"
                                                :state="Boolean(crud.form.file3)"
                                                placeholder="Elija un archivo o sueltelo aquí..."
                                                drop-placeholder="Suelta el archivo aquí...">
                                  </b-form-file>      
                                  <div v-else>
                                    <b-link :href="crud.form.file3Link" target="_blank">
                                      <b>Ver Archivo</b>
                                    </b-link>           
                                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.file3Link=''" v-b-tooltip.hover title="Cargar otro archivo">
                                      <i class="fa fa-refresh"></i>
                                    </b-button>                                         
                                  </div>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>                                                                                                        
                        </b-row>                   
                      </b-tab>
                      <b-tab no-body title="Externo">
                        <b-row>
                          <b-col md="12">
                            <b-alert show variant="info">
                              Si el producto no se vende desde el sitio web, se muestra un botón con redirección al link externo.<br>
                              Sino está configurado, se visulizará un formulario de contacto.
                            </b-alert>
                          </b-col>
                          <b-col md="6">
                            <b-form-group label="Icono Link Externo" description="Icono del link externo mostrado en el botón del sitio web">
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.icon_link_external"                                
                                            placeholder="Ingresar un icono de https://fontawesome.com/. Ej: fas fa-inbox">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group label="Leyenda Link Externo" description="Leyenda del link externo mostrado en el botón del sitio web">
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.legend_link_external"                                
                                            placeholder="Ingresar una leyenda">
                              </b-form-input>
                            </b-form-group>
                          </b-col>                   
                          <b-col md="12">
                            <b-form-group label="Link Externo" description="Link para más información sobre el producto">
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.link_external"                                
                                            placeholder="Ingresar un Link">
                              </b-form-input>
                            </b-form-group>
                          </b-col>                               
                        </b-row>                        
                      </b-tab>
                    </b-tabs>
                  </b-col>           
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Videos">
            <b-button variant="outline-success" @click="addItemVideoProducts()" class="mb-3" size="sm">
              Agregar Video
            </b-button>
            <b-alert show variant="primary">
              <h4 class="alert-heading">Formatos de videos disponibles</h4>
              <p>
                <b-icon icon="chevron-right"></b-icon> Videos con extensiones (mp4, avi, etc).<br>
                <b-icon icon="chevron-right"></b-icon> URL de desde (youtube, vimeo, dailymotion, coub)<br>
                <b-icon icon="chevron-right"></b-icon> iFrame desde cualquier plataforma
              </p>
            </b-alert>

            <b-input-group v-for="(element, index) in videosTMP" v-bind:key="index" class="mt-1" >
              <b-input-group-text>
                <b-icon icon="camera-video" />
              </b-input-group-text>

              <b-form-input type="text" 
                            v-model="videosTMP[index].link"
                            placeholder="URL del video">
              </b-form-input>

              <b-input-group-append>
                <b-button variant="outline-danger" @click="deleteItemVideoProducts(element)" v-b-tooltip.hover title="Eliminar Videos">
                  <b-icon icon="trash" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-tab>

          <b-tab no-body title="Variantes" v-if="!hasAnyVariant">
            <b-row>
              <b-col lg="12">
                <b-form-group label="Variantes" 
                              description="Esta variante del producto se visualizará en el detalle del producto y el cliente podrá informar cantidades para cada item (solo a nivel información)">                
                  <b-form-tags
                    v-model="crud.form.variants"
                    @input="crud.form.newVariants=''"
                    tag-variant="success"
                    class="mb-2 mt-2"
                    no-outer-focus
                    placeholder="Ingrese una variante y haga click en agregar"
                    :state="stateProductVariant"
                  >
                    <template v-slot="{tags, inputId, placeholder, addTag, removeTag }">
                      <b-input-group>                        
                        <b-form-input
                          v-model="crud.form.newVariants"
                          :id="inputId"
                          :placeholder="placeholder"   
                          size="sm"       
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button @click="addTag(crud.form.newVariants)" 
                                            variant="primary"
                                            size="sm">
                            Agregar
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-invalid-feedback :state="stateProductVariant">
                        Variante duplicada, no se puede agregar.
                      </b-form-invalid-feedback>
                      <ul v-if="tags.length > 0" class="mb-0">
                        <li v-for="tag in tags" :key="tag" :title="`Variante: ${tag}`" class="mt-2">
                          <span  class="d-flex align-items-center">
                            <span class="mr-2">{{ tag }}</span>
                            <b-button                            
                              size="sm"
                              variant="outline-danger"
                              @click="removeTag(tag)"
                            >
                              Quitar
                            </b-button>
                          </span>
                        </li>
                      </ul>
                      <b-form-text v-else>
                        Aun no cargo ninguna variante.
                      </b-form-text>
                    </template>
                  </b-form-tags>          
                </b-form-group>      
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Atributos" v-if="hasMeasurement">
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Profundidad" description="Ingrese la medida en cm">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.depth"
                                    required
                                    :disabled="isCostSuppliersProducts"
                                    placeholder="Ingresar una profundidad">
                      </b-form-input>
                    </b-form-group>
                  </b-col>    
                  <b-col md="12">
                    <b-form-group label="Largo" description="Ingrese la medida en cm">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.long"
                                    required
                                    :disabled="isCostSuppliersProducts"
                                    placeholder="Ingresar un largo">
                      </b-form-input>
                    </b-form-group>
                  </b-col>    
                  <b-col md="12">
                    <b-form-group label="Alto" description="Ingrese la medida en cm">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.height"
                                    required
                                    :disabled="isCostSuppliersProducts"
                                    placeholder="Ingresar un alto">
                      </b-form-input>
                    </b-form-group>
                  </b-col> 
                  <b-col md="12">
                    <b-form-group label="Peso" description="Ingrese el peso en kg">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.weight"
                                    required
                                    :disabled="isCostSuppliersProducts"
                                    placeholder="Ingresar un peso">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                   
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="12" v-if="!hasColors && !hasColorsWaist">
                    <FindObject type="productsColors" 
                                render="search"
                                @select-object="loadAttributeColor($event)" 
                                :valueID="crud.form.attribute_colors_id"                                  
                                tag="Color"/>
                  </b-col>
                  <b-col md="12" v-if="!hasColors && !hasColorsWaist">
                    <b-link @click="goToColors()" class="pull-right">Administrar Colores</b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Relacionados">
            <b-row>
              <b-col lg="6">
                <b-form-group label="Opciones de vista">
                  <b-form-select v-model="crud.form.product_related_type_view" :options="optProductRelatedTypeView"></b-form-select>      
                </b-form-group>
              </b-col>
              <b-col lg="6" v-if="crud.form.product_related_type_view=='show_product_related_subcategory'">
                <b-form-group label="Subcategorias" v-if="optProductRelatedSubcategory">
                  <b-form-select v-model="crud.form.product_related_subcategory_id" :options="optProductRelatedSubcategory"></b-form-select>      
                </b-form-group>
              </b-col>         
              <b-col lg="6" v-if="crud.form.product_related_type_view=='show_product_specific'">
                <b-form-group label="Productos" v-if="optProductRelatedSpecific" description="Para activar el buscador, ingrese como mínimo 3 letras">
                  <v-select @search="loadProductSpecificTypeView"
                            :options="optProductRelatedSpecific"
                            v-model="crud.form.product_related_specific" 
                            placeholder="Selecionar Productos" 
                            :multiple="true" 
                            :select-on-tab="true">
                  </v-select>                  
                </b-form-group>
              </b-col>                                  
            </b-row>            
          </b-tab>            
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-tabs card>

          <b-tab no-body title="Variantes">
            <b-row>
              <b-col md="12">
                <FindObject type="products" 
                            @select-object="loadVarietyProducts($event)" 
                            :valueID="crud.formSub.products_id"
                            tag="Producto"
                            disabled/>
              </b-col>
                        
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSub.code"
                                required
                                placeholder="Ingresar un código"
                                :disabled="true">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSub.name"
                                required
                                placeholder="Ingresar un nombre"
                                :disabled="true">
                  </b-form-input>
                </b-form-group>
              </b-col> 
            
              <b-col md="12">
                <b-row> 
                  <b-col md="4">                                                                       
                    <b-form-group label="Variante">
                      <b-form-select v-model="crud.formSub.typeVariant" 
                                     :options="arr.select.typeVariant" 
                                     :disabled="true"></b-form-select>
                    </b-form-group>                
                  </b-col>
                  <b-col v-show="crud.formSub.typeVariant=='COLOR' || crud.formSub.typeVariant=='COLOR-WAIST'">                    
                      <FindObject type="productsColors" 
                                  @select-object="loadVarietyColors($event)" 
                                  :valueID="crud.formSub.colors_id"                                  
                                  tag="Color"
                                  :disabled="true"/>
                  </b-col>
                  <b-col v-show="crud.formSub.typeVariant=='WAIST' || crud.formSub.typeVariant=='COLOR-WAIST'">
                      <FindObject type="productsWaist" 
                                  @select-object="loadVarietyWaist($event)" 
                                  :valueID="crud.formSub.waist_id"                                  
                                  tag="Talle"
                                  :disabled="true"/>
                  </b-col>               
                </b-row>            
              </b-col> 
            </b-row>
          </b-tab>  

          <b-tab no-body title="Detalle">
            <b-row>
              <b-col md="4">                
                <b-form-group label="Galería">
                  <Imagen :images="crud.formSub.image" 
                          @load-image="crud.formSub.image = $event" 
                          typeImage="Imagenes" 
                          :multipleImage="true" 
                          :compressImage="true"
                          :addWaterMark="true"/>
                </b-form-group>                            
              </b-col>
              <b-col md="8">              
                <b-row>
                  <b-col md="12" v-if="hasColors && !hasWaist && !hasColorsWaist">                    
                    <b-form-checkbox v-model="crud.formSub.published" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Publicado (muestra en sitio web)
                    </b-form-checkbox>            
                  </b-col>                        
                  <b-col md="12" v-if="hasColors && !hasWaist && !hasColorsWaist">
                    <hr>
                  </b-col>           
                  <b-col md="12">
                    <b-form-group label="Descripción">
                      <vue-editor v-model="crud.formSub.description"></vue-editor>              
                    </b-form-group>      
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'  
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Imagen from '@/components/inc/image/image' 
  import Param from '@/config/parameters'
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      Imagen,
      FindObject,
      VueEditor,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudProductsWeb',
          elements: {}
        }, 
        codeProduct: 0,
        parameters: {
          haveLines: Helper.hasParametersAccess(Param.P7),
          haveColors: Helper.hasParametersAccess(Param.P8),
          haveWaist: Helper.hasParametersAccess(Param.P9),
          haveMeasurement: Helper.hasParametersAccess(Param.P10),
          haveColorsWaist: Helper.hasParametersAccess(Param.P17),
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,
        },        
        tableSubColors : {
          items: [],
          fields: []
        },   
        tableSubWaist : {
          items: [],
          fields: []
        },   
        tableSubColorsWaist : {
          items: [],
          fields: []
        },
        crud: {
          form: {
            id: 0,
            name: '',            
            subline: null,
            subline_id: 0,
            subcategory: null,
            subcategory_id: 0,
            customer: null,
            customer_id: 0,
            code: '',
            image: [],            
            depth: 0,
            long: 0,
            height: 0,
            weight: 0,
            attribute_colors_id: 0,
            attribute_colors: null,
            active: true,
            featured: false,
            description: '',            
            description_short: '',     
            fileLink: '',
            file: null,
            file_name: '',
            published: true,
            fields_optionals: '',
            product_related_type_view: 'default',
            product_related_subcategory_id: 0,
            refused: false,
            variants: [],
            newVariants: '',
            erp_iva_condition: null,
            erp_iva_condition_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            videos: [],
            unit_measurement_purchase: null,
            unit_measurement_purchase_id: 0,
            unit_measurement_sale_id: 0,
            unit_measurement_sale: null,
            unit_measurement_sale_conversion: 0,
            haveCostProductPurchase: false,
            published_instagram: false,
            observations: '',
            barcode: '',
            icon_link_external: '',
            legend_link_external: '',
            link_external: '',
            order: 0,
            show_external_app: false,
            description_extended: '',            
            file1Link: '',
            file1: null,
            file1_name: '',
            file2Link: '',
            file2: null,
            file2_name: '',
            file3Link: '',
            file3: null,
            file3_name: '',
            not_sell: false,
            not_decimal_price_website: false,
            product_related_specific: null,            
          }, 
          formSub: {
            id: 0,
            name: '',
            products: null,
            products_id: 0,
            colors: null,
            colors_id: 0,
            waist: null,
            waist_id: 0,
            code: '',
            image: [],
            description: '',   
            active: true,     
            typeVariant: '',    
            published: true,
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },
        },
        arr: {
          products: [],          
          select:{
            lines: [],
            sublines:[],
            categorys: [],
            subcategorys:[],
            customers: [],
            active:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ],
            featured:[
              {code:true, label: 'Destacados'},
              {code:false, label: 'Sin Destacar'},              
            ],
            published:[
              {code:true, label: 'Publicado'},
              {code:false, label: 'Sin Publicar'},              
            ],                         
            typeVariant: [
              { value: 'COLOR', text: 'COLOR' },
              { value: 'WAIST', text: 'TALLE' },                 
              { value: 'COLOR-WAIST', text: 'COLOR + TALLE' },                 
            ],
            not_sell:[
              {code:false, label: 'Se vende por sitio web'},  
              {code:true, label: 'No se vende por sitio web'},                          
            ],                      
          }          
        },   
        filters: {
          lines: null,
          sublines: null,
          categorys: null,
          subcategorys: null,
          name: '',
          active: {code:true, label: 'Activo'},
          featured: null,
          published: null,          
          onlyEditForWeb: true,
          not_sell: null,
        },
        itemSelected: null,   
        itemSelectedVariant: null,
        showVariant: false,
        modulesActive: [], 
        optProductRelatedTypeView: [
          { text: 'Mostrar productos de su misma categoría', value: 'default', },
          { text: 'Mostrar productos de una subcategoría especifica', value: 'show_product_related_subcategory', },
          { text: 'Mostrar productos específicos', value: 'show_product_specific',}
        ],  
        optProductRelatedSubcategory: [],
        optProductRelatedSpecific: [],
        videosTMP: [],
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.modulesActive = Session.getSession().auth.user.permissions.modules      
      this.codeProduct = this.$route.params.codeProduct
      
      if(this.codeProduct){
        this.filters.name = this.codeProduct
      }
      
      this.loadFieldTable()      
      this.filterLoad()
      this.loadStyleConfig()        

      this.filterProducts()
    },
    computed: {
      hasLines() {
        return this.parameters.haveLines
      },
      hasWaist() {
        return this.parameters.haveWaist
      },
      hasColors() {
        return this.parameters.haveColors
      },
      hasColorsWaist() {
        return this.parameters.haveColorsWaist
      },
      hasAnyVariant() {      
        return this.hasColors || this.hasWaist || this.hasColorsWaist
      },
      hasAllVariant() {      
        return this.hasColors && this.hasWaist && this.hasColorsWaist
      },   
      hasMeasurement() {        
        return this.parameters.haveMeasurement
      },
      getColsProduct() {
        if(this.hasAnyVariant) {
          return 7 
        } else {
          return 12
        }
      },
      stateProductVariant() {        
        return this.crud.form.variants.indexOf(this.crud.form.newVariants.trim()) > -1 ? false : null
      },
      isCostSuppliersProducts() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.COSTOS) {
            status = true
          }
        })
        return status && !this.hasAnyVariant
      },       
    },
    methods: {
      loadStyleConfig () {
        var view_filter_products = document.getElementById('view_filters_products')
        var view_list_products = document.getElementById('view_list_products')
        var view_footer_products = document.getElementById('view_footer_products')

        if(this.hasAnyVariant) {
          var padre = document.getElementById('contain_variant_items')
          var hijo = document.getElementById('variant_items')  
          
          hijo.style.width = padre.clientWidth - 45 + 'px'

          if(window.innerWidth < 991) {
            hijo.style.width = '100%'
            hijo.style.position = "relative"
            hijo.style.right = 'auto'

            //view_filter_products.classList.remove('pr-0')
            view_list_products.classList.remove('pr-0')
            view_footer_products.classList.remove('pr-0')
          } else {
            //view_filter_products.classList.add('pr-0')
            view_list_products.classList.add('pr-0')
            view_footer_products.classList.add('pr-0')
          }
        } else {
          //view_filter_products.classList.remove('pr-0')
          view_list_products.classList.remove('pr-0')
          view_footer_products.classList.remove('pr-0')          
        }
      },
      loadFieldTable () {
        // TABLE PRODUCTOS
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle text-center", width:"30px"})        
        this.table.fields.push({key: 'sell', label: 'Vender', class:"align-middle text-center", width:"20px"})
        this.table.fields.push({key: 'image', label: 'Imagen', class:"align-middle text-center", width:"60px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"230px"})                      
        
        if(this.hasAnyVariant) {
          this.table.fields.push({key: 'variant', label: 'Variedades', class:"align-middle text-center", width:"100px"})
        }        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE PRODUCTO COLOR
        this.tableSubColors.fields.push({key: 'color', label: 'Color', class:"align-middle", width:"150px"})
        this.tableSubColors.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE PRODUCTO TALLE
        this.tableSubWaist.fields.push({key: 'waist', label: 'Talle', class:"align-middle", width:"150px"})                
        this.tableSubWaist.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})    
        
        // TABLE PRODUCTO COLOR-TALLE
        this.tableSubColorsWaist.fields.push({key: 'color', label: 'Color', class:"align-middle", width:"150px"})
        this.tableSubColorsWaist.fields.push({key: 'waist', label: 'Talle', class:"align-middle", width:"100px"})        
        this.tableSubColorsWaist.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})
      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.products.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openVariant(element)
            }
          }
        })
      },     
      openDetail(index) {
        this.table.rowSelected = index          
        this.filterProducts()        
      },       
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerProducto()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.products = data

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'  
            }
          });
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }  
          loader.hide()         
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      setConfigTableVariantShow() {
        var arrTable = document.getElementsByClassName('table-products-variant')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableVariantHide() {
        var arrTable = document.getElementsByClassName('table-products-variant')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },
      setConfigTableProductsShow() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '285px'
        }        
      },
      setConfigTableProductsHide() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },
  
      edit(item) {
        let loader = this.$loading.show();
        
        this.crud.form.id = item.id
        this.crud.form.name = item.name              

        if(item.subline) {
          this.crud.form.line_id = item.subline.line_id          
          this.crud.form.subline_id =  item.subline_id        
        } else {
          this.crud.form.line_id = 0          
          this.crud.form.subline_id = 0            
        }
        
        if(item.subcategory) {
          this.crud.form.category_id = item.subcategory.category_id          
          this.crud.form.subcategory_id = item.subcategory_id
        } else {
          this.crud.form.category_id = 0          
          this.crud.form.subcategory_id = 0          
        }

        this.crud.form.customer_id = item.customer_id
        this.crud.form.code = item.code
        this.crud.form.image = item.image        
        this.crud.form.depth = item.depth
        this.crud.form.long = item.long
        this.crud.form.height = item.height
        this.crud.form.weight = item.weight
        this.crud.form.attribute_colors_id = (item.attribute_colors_id!=null) ? item.attribute_colors_id : 0
        this.crud.form.active = item.active
        this.crud.form.featured = item.featured
        this.crud.form.description = item.description      
        this.crud.form.description_short = (item.description_short!=null) ? item.description_short : ''
        this.crud.form.file = null
        this.crud.form.fileLink = item.attach    
        this.crud.form.file_name = (item.attach_name!=null) ? item.attach_name : ''
        this.crud.form.published = item.published
        this.crud.form.product_related_type_view = (item.product_related_type_view!=null) ? item.product_related_type_view : 'default'
        this.crud.form.product_related_subcategory_id = (item.product_related_subcategory_id!=null) ? item.product_related_subcategory_id : 0
        this.crud.form.refused = item.refused   
        this.crud.form.published_instagram = item.published_instagram
        this.crud.form.observations = (item.observations!=null) ? item.observations : ''     
        this.crud.form.barcode = (item.barcode!=null) ? item.barcode : ''
        this.crud.form.icon_link_external = (item.icon_link_external!=null) ? item.icon_link_external : ''
        this.crud.form.legend_link_external = (item.legend_link_external!=null) ? item.legend_link_external : ''
        this.crud.form.link_external = (item.link_external!=null) ? item.link_external : ''
        this.crud.form.order = item.order
        this.crud.form.show_external_app = item.show_external_app
        this.crud.form.description_extended = (item.description_extended!=null) ? item.description_extended : ''
        this.crud.form.file1 = null
        this.crud.form.file1Link = item.attach_one
        this.crud.form.file1_name = (item.attach_one_name!=null) ? item.attach_one_name : ''
        this.crud.form.file2 = null
        this.crud.form.file2Link = item.attach_two
        this.crud.form.file2_name = (item.attach_two_name!=null) ? item.attach_two_name : ''
        this.crud.form.file3 = null
        this.crud.form.file3Link = item.attach_three
        this.crud.form.file3_name = (item.attach_three_name!=null) ? item.attach_three_name : ''
        this.crud.form.not_sell = item.not_sell
        this.crud.form.not_decimal_price_website = item.not_decimal_price_website

        if(item.product_related_specific) {
          let product_related_specific = JSON.parse(item.product_related_specific)
          
          if(Array.isArray(product_related_specific) &&
             product_related_specific[0] && 
             product_related_specific[0]["code"] &&
             product_related_specific[0]["label"]) {

              this.crud.form.product_related_specific = product_related_specific
          } else {            
            this.crud.form.product_related_specific = null
          }          
        } else {
          this.crud.form.product_related_specific = null
        }        

        if(item.variants) {
          this.crud.form.variants = item.variants.split(',')
          this.crud.form.newVariants = ''
        } else {
          this.crud.form.variants = []
          this.crud.form.newVariants = ''          
        }
                
        if(item.image) {          
          this.crud.form.image = []
          item.image.forEach(element => {
            this.crud.form.image.push({ path: element })
          });                    
        } else {
          this.crud.form.image = []
        }

        this.videosTMP = []
        if(item.videos)
          this.videosTMP = JSON.parse(item.videos)
                                
        if(item.product_purchase) {
          this.crud.form.unit_measurement_purchase = item.product_purchase.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.product_purchase.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = true
        } else {
          this.crud.form.unit_measurement_purchase = item.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = false
        }

        this.crud.form.unit_measurement_sale = item.unit_measurement_sale
        this.crud.form.unit_measurement_sale_id = item.unit_measurement_sale_id 
        this.crud.form.unit_measurement_sale_conversion = item.unit_measurement_sale_conversion

        this.crud.form.erp_iva_condition = item.erp_iva_condition
        this.crud.form.erp_iva_condition_id = item.erp_iva_condition_id

        if(item.accounting_account){
          this.crud.form.accounting_accounts = item.accounting_account.accounting_account
          this.crud.form.accounting_accounts_id = item.accounting_account.accounting_accounts_id  
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }

        this.loadSubcategoryTypeView()

        setTimeout(()=>{
          this.modal.form.title = "Editar Producto"
          this.modal.form.active = true

          loader.hide()
        },500)
      },
      save() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var file = new FormData();
        if(this.crud.form.fileLink) {
          file.append("file", this.crud.form.fileLink);
        } else {        
          if(this.crud.form.file) {          
            file.append("file", this.crud.form.file);
          }
        }  
        var file1 = new FormData();
        if(this.crud.form.file1Link) {
          file1.append("file1", this.crud.form.file1Link);
        } else {        
          if(this.crud.form.file1) {          
            file1.append("file1", this.crud.form.file1);
          }
        }          
        var file2 = new FormData();
        if(this.crud.form.file2Link) {
          file2.append("file2", this.crud.form.file2Link);
        } else {        
          if(this.crud.form.file2) {          
            file2.append("file2", this.crud.form.file2);
          }
        }          
        var file3 = new FormData();
        if(this.crud.form.file3Link) {
          file3.append("file3", this.crud.form.file3Link);
        } else {        
          if(this.crud.form.file3) {          
            file3.append("file3", this.crud.form.file3);
          }
        }          

        this.crud.form.videos = JSON.stringify(this.videosTMP)
        
        // armo el array de id para los producto relacionados especificos 
        if (this.crud.form.product_related_specific) {
          this.crud.form.product_related_specific = JSON.stringify(this.crud.form.product_related_specific);
        } else {
          this.crud.form.product_related_specific = null
        }

        var result = serviceAPI.editarProducto(JSON.stringify(this.crud.form), image, file, file1, file2, file3);        

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterProducts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },  
      
      addItemVideoProducts() {
        this.videosTMP.push({
          link: ''
        })
      },
      deleteItemVideoProducts(item) {
        var i = this.videosTMP.indexOf( item );
    
        if ( i !== -1 ) {
            this.videosTMP.splice( i, 1 );
        }
      },

      editSub(item) {        
        this.crud.formSub.id = item.id        
        this.crud.formSub.name = item.name           
        this.crud.formSub.products_id = item.products_id
        this.crud.formSub.colors_id = item.colors_id
        this.crud.formSub.waist_id = item.waist_id
        this.crud.formSub.code = item.code
        this.crud.formSub.image = item.image
        this.crud.formSub.description = item.description
        this.crud.formSub.active = item.active
        this.crud.formSub.published = item.published

        if(this.hasColorsWaist) {
          this.crud.formSub.typeVariant = 'COLOR-WAIST'
        } else {
          if(this.hasColors) {
            this.crud.formSub.typeVariant = 'COLOR'
          } else {
            this.crud.formSub.typeVariant = 'WAIST'
          }
        }

        if(item.image) {          
          this.crud.formSub.image = []
          item.image.forEach(element => {
            this.crud.formSub.image.push({ path: element })
          });                    
        } else {
          this.crud.formSub.image = []
        }

        this.modal.formSub.title = "Editar Variedad"
        this.modal.formSub.active = true
      },
      saveSub() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.formSub.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var typeVariant = this.getTypeVariant()

        var result = serviceAPI.editarVariedad(JSON.stringify(this.crud.formSub), image, typeVariant);        

        result.then((response) => {
          this.modal.formSub.active = false                   
          this.filterProducts(true)     
          loader.hide()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },      
      loadAttributeColor(object) {
        if(object){
          this.crud.form.attribute_colors = object
          this.crud.form.attribute_colors_id = object.id
        } else {
          this.crud.form.attribute_colors = null
          this.crud.form.attribute_colors_id = 0
        }
      },


      loadProductsLine (object) {
        if(object){
          this.crud.form.line = object
          this.crud.form.line_id = object.id
          //this.crud.form.subline = null
          //this.crud.form.subline_id = 0          
        } else {
          this.crud.form.line = null
          this.crud.form.line_id = 0
          //this.crud.form.subline = null
          //this.crud.form.subline_id = 0          
        }
      },  
      loadProductsSubline (object) {
        if(object){
          this.crud.form.subline = object
          this.crud.form.subline_id = object.id
        } else {
          this.crud.form.subline = null
          this.crud.form.subline_id = 0
        }
      },  
      loadProductsCategory (object) {
        if(object){
          this.crud.form.category = object
          this.crud.form.category_id = object.id
          //this.crud.form.subcategory = null
          //this.crud.form.subcategory_id = 0          
        } else {
          this.crud.form.category = null
          this.crud.form.category_id = 0
          //this.crud.form.subcategory = null
          //this.crud.form.subcategory_id = 0          
        }
      },           
      loadProductsSubcategory (object) {
        if(object){
          this.crud.form.subcategory = object
          this.crud.form.subcategory_id = object.id                    
        } else {
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0
        }
      },   
      loadVarietyProducts (object) {
        if(object){
          this.crud.formSub.products = object
          this.crud.formSub.products_id = object.id
        } else {
          this.crud.formSub.products = null
          this.crud.formSub.products_id = 0
        }
      }, 
      loadVarietyColors (object) {
        if(object){
          this.crud.formSub.colors = object
          this.crud.formSub.colors_id = object.id
        } else {
          this.crud.formSub.colors = null
          this.crud.formSub.colors_id = 0
        }
      }, 
      loadVarietyWaist (object) {
        if(object){
          this.crud.formSub.waist = object
          this.crud.formSub.waist_id = object.id
        } else {
          this.crud.formSub.waist = null
          this.crud.formSub.waist_id = 0
        }
      },    
      loadProductsCustomer (object) {
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id                   
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadSubcategoryTypeView () {
        var result = serviceAPI.obtenerCategoria()

        result.then((response) => {
          var data = response.data              
          
          this.optProductRelatedSubcategory = []
          data.forEach(element => {
            element.subcategorys.forEach(element1 => {      
              this.optProductRelatedSubcategory.push({text: element.name + ' - ' + element1.name, value: element1.id})                                                            
            });                                
          });       
        })         
        .catch(error => {
          this.optProductRelatedSubcategory = []
        })
      },
      loadProductSpecificTypeView (search, loading) {        
        loading(true)
        if(search && search.length>2) {
          var result = serviceAPI.filtrarProducto({
            name: search
          },1);

          result.then((response) => {
            var data = response.data.data              
            
            this.optProductRelatedSpecific = []
            data.forEach(element => {            
              this.optProductRelatedSpecific.push({label: element.code + " - " + element.name, code: element.id})                                                                        
            });       

            loading(false)
          })         
          .catch(error => {
            this.optProductRelatedSpecific = []
          })
        }
      },      
      filterLoad() {
        this.filterLoadLines()
        this.filterLoadCategorys()
      },
      filterLoadLines() {
        var result = serviceAPI.obtenerLinea()        
        result.then((response) => {
          var data = response.data    
          
          data.forEach(element => {      
            this.arr.select.lines.push({code: element.id, label: element.name})                                                            
          });                                
        })   
      },
      filterLoadSubLines(val) {        
        this.arr.select.sublines=[]
        if(val) {
          var result = serviceAPI.obtenerLinea()        
          result.then((response) => {
            var data = response.data    
            
            data.forEach(element => {         
              if(element.id == val.code) {
                element.sublines.forEach(element1 => {
                  this.arr.select.sublines.push({code: element1.id, label: element1.name})                        
                }); 
              }
            });                                
          })   
        }
      },
      filterLoadCategorys() {
        var result = serviceAPI.obtenerCategoria()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.categorys.push({code: element.id, label: element.name})                        
            /*
            element.subcategorys.forEach(element1 => {
              this.arr.select.subcategorys.push({category: element.name, subcategory: element1.name, code: element1.id})                        
            });
            */                                    
          });                                
        })   
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        if(val) {          
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {          
              if(element.id == val.code) {
                element.subcategorys.forEach(element1 => {
                  this.arr.select.subcategorys.push({code: element1.id, label: element1.name})
                }); 
              }                                  
            });                                
          })   
        }
      },

      filterProducts(forceOpenVariant=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarProducto(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data          
          this.arr.products = data.data  

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenVariant) {
              if(element.id == this.itemSelected.id) {
                this.openVariant(element)
              }              
            }
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }   
                    
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {  
          this.table.isBusy = false
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },

      getTypeVariant() {
        var typeVariant = ''
        if(this.hasAllVariant) {          
          if(this.crud.formSub.typeVariant=='COLOR') {
            typeVariant = 'COLOR'
          }
          if(this.crud.formSub.typeVariant=='WAIST') {
            typeVariant = 'WAIST'
          }   
          if(this.crud.formSub.typeVariant=='COLOR-WAIST') {
            typeVariant = 'COLOR-WAIST'
          }                    
        } else {        
          if(this.hasColors) {
            typeVariant = 'COLOR'
          }

          if(this.hasWaist) {
            typeVariant = 'WAIST'
          }          

          if(this.hasColorsWaist) {
            typeVariant = 'COLOR-WAIST'
          }          
        }
        return typeVariant
      },
      getTypeVariantAlt() {
        var typeVariant = ''        
        if(this.hasAnyVariant) {                        
          if(this.hasColors) {
            typeVariant = 'COLOR'
          }

          if(this.hasWaist) {
            typeVariant = 'WAIST'
          }          

          if(this.hasColorsWaist) {
            typeVariant = 'COLOR-WAIST'
          }          
        }
        return typeVariant
      },
      getVariantProduct(item) {
        if(item.colors.length || item.waist.length || item.colors_waist.length) {
          return true
        } else {
          return false
        }        
      },      

      openVariant(item) {
        this.showVariant = true
        this.itemSelected = item
        
        this.tableSubColors.items = item.colors
        this.tableSubColors.items.forEach(element => {
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });          
      
        this.tableSubWaist.items = item.waist
        this.tableSubWaist.items.forEach(element => {
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });           
      
        this.tableSubColorsWaist.items = item.colors_waist
        this.tableSubColorsWaist.items.forEach(element => {            
          if(!element.active) {
            element._rowVariant = 'danger'  
          } else {
            element._rowVariant = ''  
          }
        });                                   
      },

      goToColors() {
        this.$router.push({ name: 'ProductsStaffCrudColors' })
      }
    }    
  }
</script>
<style>
  .crud-products-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-products-code {
    color: gray;
  }
  .crud-product-image {
    height: 40px;
  }

  .products-tablesub-observations {
    color: darkred;
  }
  .crud-product-table-show {
    font-size: 15px;
  }  
  .crud-product-add-variant {
    margin-top: 5px;
  }
  .crud-product-close-variant  {
    margin-top: -15px;
  }
  .crud-product-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
  .crud-products-variant-positions {
    position: fixed;
  }
  #variant_items {
    position: fixed;
    right: 15px;    
  }
  .crud-products-table-button-variant {
    padding: 2px;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .table-products-variant {
    overflow: auto;
    max-height: 350px;
  }
  .crud-product-interested {
    font-size: 13px;
  }
  .crud-product-featured-custom {
    color: red; 
    font-size: 18px;
    float: right;     
  }  
  .crud-product-sell-custom {
    color: darkgreen; 
    font-size: 18px;      
  }
.crud-product-notsell-custom {
    color: darkred; 
    font-size: 18px;
  }  
  .product-web-ql-editor .ql-editor {
    min-height: 300px !important;
  }  
</style>